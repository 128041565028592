/**
* Template Name: Minimal
* Template URL: https://templatemag.com/minimal-bootstrap-template/
* Author: TemplateMag.com
* License: https://templatemag.com/license/
*/

body {
  height: 100%;
  margin: 0;
  background-color: #f2f2f2;
  font-family: 'EB Garamond', serif;
  font-weight: 300;
  font-size: 16px;
  color: #555;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

/* Titles */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  color: black;
}
h3 {
  font-size: 25px
}

/* Paragraph & Typographic */

p {
  line-height: 28px;
  margin-bottom: 25px;
}

.centered {
  text-align: center;
}

/* Links */

a {
  color: white;
  word-wrap: break-word;
  -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
  -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
  -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
  -o-transition: color 0.1s ease-in, background 0.1s ease-in;
  transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover, a:focus {
  color: #7b7b7b;
  text-decoration: none;
  outline: 0;
}

a:before, a:after {
  -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
  -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
  -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
  -o-transition: color 0.1s ease-in, background 0.1s ease-in;
  transition: color 0.1s ease-in, background 0.1s ease-in;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* ==========================================================================
   Wrap Sections
   ========================================================================== */
.container {
  align-items: center;
}
#headerwrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  background: black;
          /*url(../img/header-bg.jpg) no-repeat center top;*/
  /*margin-top: -10px;*/
  /*padding-top: 20px;*/
  display: flex;
  text-align: center;
  /*background-attachment: relative;*/
  /*background-position: center center;*/
  /*min-height: 700px;*/
  /*width: 100%;*/
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#headerwrap h1 {
  /*margin-top: 150px;*/
  color: white;
  font-size: 70px;
  font-weight: 700;
}

#headerwrap h3 {
  color: white;
  font-size: 30px;
  font-weight: 100;
  font-family: 'EB Garamond', serif;
  font-style: oblique;
}

#headerwrap .logo {
  /*text-align: left;*/
  /*margin-left: 20px;*/
  /*margin-top: 15px;*/
}

/* Footer Wrap */
#line{
  background-color: #202124;
  width: 100%;
  height: 4px;
}
.f {
  /*#1a1a1a*/
  /*#202124*/
  background: #202124;
  padding-top: 70px;
  padding-bottom: 10px;
  text-align: center
}


/*.contact-footer {*/
/*  font-size: 300px;*/
/*  size: 300px;*/
/*}*/
.f h3 {
  color: white;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
}

.f i {
  font-size: 19px;
  color: #fff;
  padding: 3px;
}
.contact-block {
  padding: 30px;
}
#footer {
  background: #202124;
  padding-top: 70px;
  padding-bottom: 10px;
  text-align: center
}
#footer i {
  color: #fff;
  padding: 3px;
  margin: 10px;
  font-size: 80px;
}
#footer h3 {
  color: white;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
}

.f p {
  font-size: 14px;
  color: #f2f2f2;
  font-family: 'Verdana', sans-serif;
  /*text-align: initial;*/
  /*font-size: 8px;*/
}
.f .stratch-clmn {
  text-align: initial;
}
h4 {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
  font-size: 32px;
  font-weight: bold;
  color: white;
}
.testnet-card {
  border: 1px solid #3c4043;
  background: #202124;
}
.testnet-card-testnet {
  text-align: initial;
  background: #202124;
  border: 1px solid #3c4043;
  width: calc(33.33% - 22px);
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  overflow: hidden;
}
.delegate-button {
  background-image: linear-gradient(transparent 50%, white 50%);
  background-size: 200% 200%;
  background-repeat: no-repeat;
  transition: .2s;
}
.delegate-button:hover {
  background-position: 0 100%;
  text-decoration:none;
  color: #202124 !important;
}
.delegate-button:hover p {
  text-decoration:none;
  color: #202124 !important;
}

#f img {
  text-align: center;
}
.contribution-style{
  font-weight: bold;
  margin: 0;
}
h5 {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
  color: white;
}
.stratch-clmn {
    display: flex;
    align-items: stretch;
}
/* MENU CONF*/

.menu {
  position: fixed;
  right: -200px;
  width: 260px;
  height: 100%;
  top: 0;
  z-index: 10;
  text-align: left;
}

.menu.menu-open {
  right: 0px;
}

.menu-wrap {
  position: absolute;
  top: 0;
  left: 60px;
  background: #1a1a1a;
  width: 200px;
  height: 100%;
}

.menu h1.logo a {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
}

.menu h1.logo a:hover {
  color: #f85c37;
}

.menu img.logo {
  margin: 20px 0;
  max-width: 160px;
}

.menu a {
  margin-left: 20px;
  color: #808080;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.menu a:hover {
  color: #ffffff;
}

.menu a:active {
  color: #ffffff;
}

.menu a>i {
  float: left;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 25px;
  font-size: 14px;
  line-height: 40px;
  margin: 25px 2px;
}

.menu-close {
  cursor: pointer;
  display: block;
  position: absolute;
  font-size: 14px;
  color: #808080;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 20px;
  right: 5px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.menu-close:hover {
  color: #ffffff;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

/* Push the body after clicking the menu button */

.body-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}

.body-push-toright {
  left: 200px;
}

.body-push-toleft {
  left: -200px;
}

.menu, .body-push {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#menuToggle {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 11;
  display: block;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

#menuToggle:hover {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}



/* Contact Form */

.contact-form {
  text-align: left;
}

.contact-form label {
   color: #999;
}

.contact-form input, .contact-form textarea {
  padding: 10px 12px;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  height: auto;
}

.contact-form .form-send {
  text-align: center;
}

.contact-form .form-send button  {
  background: #f0ad4e;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
}

.contact-form .form-send button:hover {
  background: #ffbc5d;
}

.contact-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact-form .loading {
  display: none;
  color: #555;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin: 15px 0;
}

.contact-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.contact-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}

.contact-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}


/* Copyrights */

#copyrights {
  background: black;
  padding: 20px 0;
  text-align: center;
  color: #fff;
}

#copyrights a {
  color: #f0ad4e;
}

#copyrights p {
  margin-bottom: 5px;
}

.credits {
  color: #999;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}
@media (max-width: 820px) {
  .testnet-card-testnet {
    width: calc(50% - 22px);
  }
}
@media (max-width: 335px) {
  #headerwrap h1 {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .contact-block {
    flex-direction: column;
  }
  .responsive {
    flex-direction: column;
    align-items: center;
  }
  .testnet-card-testnet {
    width: 100%;
  }
}

.btn-group button {
  font-family: 'Verdana', sans-serif;
  background: #202124;
  border: 1px solid #3c4043;
  color: white; /* White text */
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}
.btn-radius-left {
  border-radius: 30px 0 0 30px ;
}
.btn-radius-right {
  border-radius: 0 30px 30px 0 ;
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

 /*Add a background color on hover */
.btn-group button:hover {
  background-color: white;
  color: black;
}
.selected {
  background-color: white !important;
  color: black !important;
}
/* spiner */
.lds-ring {
/*  margin: 50px;*/
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.img-fluid {
  border-radius: 50%;
  width: 90px
}

.spacer {
  height: 3rem; /* Высота отступа */
}
[data-loading] {
  display: none;
}
