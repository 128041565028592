body {
  color: #555;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background-color: #f2f2f2;
  height: 100%;
  margin: 0;
  font-family: EB Garamond, serif;
  font-size: 16px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-family: Oswald, sans-serif;
  font-weight: 300;
}

h3 {
  font-size: 25px;
}

p {
  margin-bottom: 25px;
  line-height: 28px;
}

.centered {
  text-align: center;
}

a {
  color: #fff;
  word-wrap: break-word;
  -o-transition: color .1s ease-in, background .1s ease-in;
  transition: color .1s ease-in, background .1s ease-in;
}

a:hover, a:focus {
  color: #7b7b7b;
  outline: 0;
  text-decoration: none;
}

a:before, a:after {
  -o-transition: color .1s ease-in, background .1s ease-in;
  transition: color .1s ease-in, background .1s ease-in;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

.container {
  align-items: center;
}

#headerwrap {
  text-align: center;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: #000 0 0 / cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
}

#headerwrap h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
}

#headerwrap h3 {
  color: #fff;
  font-family: EB Garamond, serif;
  font-size: 30px;
  font-style: oblique;
  font-weight: 100;
}

#line {
  background-color: #202124;
  width: 100%;
  height: 4px;
}

.f {
  text-align: center;
  background: #202124;
  padding-top: 70px;
  padding-bottom: 10px;
}

.f h3 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 700;
}

.f i {
  color: #fff;
  padding: 3px;
  font-size: 19px;
}

.contact-block {
  padding: 30px;
}

#footer {
  text-align: center;
  background: #202124;
  padding-top: 70px;
  padding-bottom: 10px;
}

#footer i {
  color: #fff;
  margin: 10px;
  padding: 3px;
  font-size: 80px;
}

#footer h3 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 700;
}

.f p {
  color: #f2f2f2;
  font-family: Verdana, sans-serif;
  font-size: 14px;
}

.f .stratch-clmn {
  text-align: initial;
}

h4 {
  letter-spacing: 1px;
  color: #fff;
  font-family: Oswald, sans-serif;
  font-size: 32px;
  font-weight: bold;
}

.testnet-card {
  background: #202124;
  border: 1px solid #3c4043;
}

.testnet-card-testnet {
  text-align: initial;
  background: #202124;
  border: 1px solid #3c4043;
  flex-direction: column;
  justify-content: space-between;
  width: calc(33.33% - 22px);
  min-height: 200px;
  margin: 10px;
  display: flex;
  overflow: hidden;
}

.delegate-button {
  background-image: linear-gradient(#0000 50%, #fff 50%);
  background-repeat: no-repeat;
  background-size: 200% 200%;
  transition: all .2s;
}

.delegate-button:hover {
  background-position: 0 100%;
  text-decoration: none;
  color: #202124 !important;
}

.delegate-button:hover p {
  text-decoration: none;
  color: #202124 !important;
}

#f img {
  text-align: center;
}

.contribution-style {
  margin: 0;
  font-weight: bold;
}

h5 {
  letter-spacing: 1px;
  color: #fff;
  font-family: Oswald, sans-serif;
}

.stratch-clmn {
  align-items: stretch;
  display: flex;
}

.menu {
  z-index: 10;
  text-align: left;
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -200px;
}

.menu.menu-open {
  right: 0;
}

.menu-wrap {
  background: #1a1a1a;
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 60px;
}

.menu h1.logo a {
  letter-spacing: .15em;
  text-transform: uppercase;
  color: #fff;
  margin-top: 20px;
  font-family: Oswald, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

.menu h1.logo a:hover {
  color: #f85c37;
}

.menu img.logo {
  max-width: 160px;
  margin: 20px 0;
}

.menu a {
  color: gray;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

.menu a:hover, .menu a:active {
  color: #fff;
}

.menu a > i {
  float: left;
  vertical-align: middle;
  text-align: left;
  width: 25px;
  margin: 25px 2px;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
}

.menu-close {
  cursor: pointer;
  color: gray;
  -o-transition: all .1s ease-in-out;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 20px;
  right: 5px;
}

.menu-close:hover {
  color: #fff;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.body-push {
  position: relative;
  left: 0;
  overflow-x: hidden;
}

.body-push-toright {
  left: 200px;
}

.body-push-toleft {
  left: -200px;
}

.menu, .body-push {
  -o-transition: all .3s ease;
  transition: all .3s;
}

#menuToggle {
  z-index: 11;
  text-align: center;
  color: #fff;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  background: #00000040;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
}

#menuToggle:hover {
  color: #fff;
  -o-transition: all .1s ease-in-out;
  background: #0003;
  transition: all .1s ease-in-out;
}

.contact-form {
  text-align: left;
}

.contact-form label {
  color: #999;
}

.contact-form input, .contact-form textarea {
  box-shadow: none;
  border-radius: 0;
  height: auto;
  padding: 10px 12px;
  font-size: 14px;
}

.contact-form .form-send {
  text-align: center;
}

.contact-form .form-send button {
  color: #fff;
  cursor: pointer;
  background: #f0ad4e;
  border: 0;
  padding: 10px 30px;
  transition: all .4s;
}

.contact-form .form-send button:hover {
  background: #ffbc5d;
}

.contact-form .validate {
  color: red;
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 400;
  display: none;
}

.contact-form .loading {
  color: #555;
  text-align: center;
  background: #fff;
  margin: 15px 0;
  padding: 15px;
  display: none;
}

.contact-form .loading:before {
  content: "";
  border: 3px solid #18d26e;
  border-top-color: #eee;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: 1s linear infinite animate-loading;
  display: inline-block;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contact-form .error-message {
  color: #fff;
  text-align: center;
  background: #ed3c0d;
  margin: 15px 0;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact-form .sent-message {
  color: #fff;
  text-align: center;
  background: #18d26e;
  margin: 15px 0;
  padding: 15px;
  font-weight: 600;
  display: none;
}

#copyrights {
  text-align: center;
  color: #fff;
  background: #000;
  padding: 20px 0;
}

#copyrights a {
  color: #f0ad4e;
}

#copyrights p {
  margin-bottom: 5px;
}

.credits {
  color: #999;
}

html, body {
  overflow-x: hidden;
}

body {
  position: relative;
}

@media (width <= 820px) {
  .testnet-card-testnet {
    width: calc(50% - 22px);
  }
}

@media (width <= 335px) {
  #headerwrap h1 {
    font-size: 50px;
  }
}

@media (width <= 768px) {
  .contact-block {
    flex-direction: column;
  }

  .responsive {
    flex-direction: column;
    align-items: center;
  }

  .testnet-card-testnet {
    width: 100%;
  }
}

.btn-group button {
  color: #fff;
  cursor: pointer;
  float: left;
  background: #202124;
  border: 1px solid #3c4043;
  padding: 10px 24px;
  font-family: Verdana, sans-serif;
}

.btn-radius-left {
  border-radius: 30px 0 0 30px;
}

.btn-radius-right {
  border-radius: 0 30px 30px 0;
}

.btn-group button:not(:last-child) {
  border-right: none;
}

.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

.btn-group button:hover {
  color: #000;
  background-color: #fff;
}

.selected {
  color: #000 !important;
  background-color: #fff !important;
}

.lds-ring {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.img-fluid {
  border-radius: 50%;
  width: 90px;
}

.spacer {
  height: 3rem;
}

[data-loading] {
  display: none;
}

/*# sourceMappingURL=index.4daad2c6.css.map */
